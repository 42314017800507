//reportExcept : report에 대한 필요 없는 설비는 제외
//periodExcept : searchPeriod에 대한 자동 구현 불가능한(양식이 달라서) 설비는 제외
//periodFakeNg : searchPeriod에서 Fake NG를 포함하는 것에 대한 구분(대부분 1공장 설비)
//failureExcept : failureReport에서 EV2020 60FR, EV2020 60RR등 나눠진 Data를 EV2020 60kW로 합치기 위해서 EV2020 60FR, EV2020 60RR을 제외
export const targets = [  
    { name: "TR2 SMT", pr: "tr2smtpr", dt: "tr2smtdt", dr: "tr2smtdr", qw: "tr2smtqw",  reportExcept: "0", periodExcept: "0", periodFakeNg: "0", failureExcept: "0"   },
    { name: "TR2 Crimping", pr: "tr2crimpingpr", dt: "tr2crimpingdt", dr: "tr2crimpingdr", qw: "tr2crimpingqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "0", failureExcept: "0"  },
    { name: "TR2 FPCA", pr: "tr2fpcapr", dt: "tr2fpcadt", dr: "tr2fpcadr", qw: "tr2fpcaqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "0", failureExcept: "0"  },
    { name: "TR2 BFA 3P", pr: "tr2bfa3ppr", dt: "tr2bfa3pdt", dr: "tr2bfa3pdr", qw: "tr2bfa3pqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "0", failureExcept: "0" },     
    { name: "TR3 SMT", pr: "tr3smtpr", dt: "tr3smtdt", dr: "tr3smtdr", qw: "tr3smtqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "0", failureExcept: "0"  },
    { name: "TR3 Crimping", pr: "tr3crimpingpr", dt: "tr3crimpingdt", dr: "tr3crimpingdr", qw: "tr3crimpingqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "0", failureExcept: "0"  },
    { name: "TR3 FPCA", pr: "tr3fpcapr", dt: "tr3fpcadt", dr: "tr3fpcadr", qw: "tr3fpcaqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "0", failureExcept: "0"  },
    { name: "EVO 1", pr: "tr3bfa2ppr", dt: "tr3bfa2pdt", dr: "tr3bfa2pdr", qw: "tr3bfa2pqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "0", failureExcept: "0"  },
    { name: "EVO 2", pr: "tr3bfa3ppr", dt: "tr3bfa3pdt", dr: "tr3bfa3pdr", qw: "tr3bfa3pqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "0", failureExcept: "0"  },   
    { name: "EVO 3", pr: "tr2bfa2ppr", dt: "tr2bfa2pdt", dr: "tr2bfa2pdr", qw: "tr2bfa2pqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "0", failureExcept: "0"  },                         
    { name: "EVO Hybrid", pr: "evopr", dt: "evodt", dr: "evodr", qw: "evoqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "0", failureExcept: "0"  },     
    { name: "EV2020 60FR", pr: "ev2020_60frpr", dt: "ev2020_60frdt", dr: "ev2020_60frdr", qw: "ev2020_60frqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "0", failureExcept: "1"  },     
    { name: "EV2020 60RR", pr: "ev2020_60rrpr", dt: "ev2020_60rrdt", dr: "ev2020_60rrdr", qw: "ev2020_60rrqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "0", failureExcept: "1"  },     
    { name: "EV2020 4060FR", pr: "ev2020_4060frpr", dt: "ev2020_4060frdt", dr: "ev2020_4060frdr", qw: "ev2020_4060frqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "0", failureExcept: "1"  },     
    { name: "EV2020 4060RR", pr: "ev2020_4060rrpr", dt: "ev2020_4060rrdt", dr: "ev2020_4060rrdr", qw: "ev2020_4060rrqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "0", failureExcept: "1"  },     
    { name: "EV2020 60kW", pr: "", dt: "", dr: "", qw: "", reportExcept: "1", periodExcept: "1", periodFakeNg: "0", failureExcept: "0" },
    { name: "EV2020 4060kW", pr: "", dt: "", dr: "", qw: "", reportExcept: "1", periodExcept: "1", periodFakeNg: "0", failureExcept: "0" },
    { name: "Ford 1 Front", pr: "ford1frpr", dt: "ford1frdt", dr: "ford1frdr", qw: "ford1frqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "1", failureExcept: "0"  },     
    { name: "Ford 1 Rear", pr: "ford1rrpr", dt: "ford1rrdt", dr: "ford1rrdr", qw: "ford1rrqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "1", failureExcept: "0"  },     
    { name: "Ford 2 Front", pr: "ford2frpr", dt: "ford2frdt", dr: "ford2frdr", qw: "ford2frqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "1", failureExcept: "0"  },     
    { name: "Ford 2 Rear", pr: "ford2rrpr", dt: "ford2rrdt", dr: "ford2rrdr", qw: "ford2rrqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "1", failureExcept: "0"  },     
    { name: "Ford 3 Front", pr: "ford3frpr", dt: "ford3frdt", dr: "ford3frdr", qw: "ford3frqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "1", failureExcept: "0"  },     
    { name: "Ford 3 Rear", pr: "ford3rrpr", dt: "ford3rrdt", dr: "ford3rrdr", qw: "ford3rrqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "1", failureExcept: "0"  },     
    { name: "FCA 1", pr: "fca1pr", dt: "fca1dt", dr: "fca1dr", qw: "fca1qw", reportExcept: "0", periodExcept: "0", periodFakeNg: "1", failureExcept: "0"  },    
    { name: "FCA 2", pr: "fca2pr", dt: "fca2dt", dr: "fca2dr", qw: "fca2qw", reportExcept: "0", periodExcept: "0", periodFakeNg: "1", failureExcept: "0"  },    
    { name: "BT6", pr: "bt6pr", dt: "bt6dt", dr: "bt6dr", qw: "bt6qw", reportExcept: "0", periodExcept: "0", periodFakeNg: "1", failureExcept: "0"  },    
    { name: "BMW12V", pr: "bmw12vpr", dt: "bmw12vdt", dr: "bmw12vdr", qw: "bmw12vqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "1", failureExcept: "0"  },    
    { name: "BMW48V", pr: "bmw48vpr", dt: "bmw48vdt", dr: "bmw48vdr", qw: "bmw48vqw", reportExcept: "0", periodExcept: "1", periodFakeNg: "0", failureExcept: "0"  },    
    { name: "FCA FLEX BC", pr: "fca_flex_bcpr", dt: "fca_flex_bcdt", dr: "fca_flex_bcdr", qw: "fca_flex_bcqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "1", failureExcept: "0"  },
    { name: "FCA FLEX PO", pr: "fca_flex_popr", dt: "fca_flex_podt", dr: "fca_flex_podr", qw: "fca_flex_poqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "1", failureExcept: "0"  },
    { name: "FCA FLEX 67", pr: "fca_flex_67pr", dt: "fca_flex_67dt", dr: "fca_flex_67dr", qw: "fca_flex_67qw", reportExcept: "0", periodExcept: "0", periodFakeNg: "1", failureExcept: "0"  },
    { name: "FCA FLEX NE", pr: "fca_flex_nepr", dt: "fca_flex_nedt", dr: "fca_flex_nedr", qw: "fca_flex_neqw", reportExcept: "0", periodExcept: "0", periodFakeNg: "1", failureExcept: "0"  },
    { name: "FCA FLEX 45", pr: "fca_flex_45pr", dt: "fca_flex_45dt", dr: "fca_flex_45dr", qw: "fca_flex_45qw", reportExcept: "0", periodExcept: "0", periodFakeNg: "1", failureExcept: "0"  },   
    { name: "Thermistor Tape", pr: "thermistortapepr", dt: "thermistortapedt", dr: "thermistortapedr", qw: "", reportExcept: "1", periodExcept: "1", periodFakeNg: "0", failureExcept: "1"   },    
    { name: "Thermistor Pad", pr: "thermistorpadpr", dt: "thermistorpaddt", dr: "thermistorpaddr", qw: "", reportExcept: "1", periodExcept: "1", periodFakeNg: "0", failureExcept: "1"  },    
    { name: "Bending EVO", pr: "bendingevopr", dt: "bendingevodt", dr: "bendingevodr", qw: "", reportExcept: "1", periodExcept: "1", periodFakeNg: "0", failureExcept: "1"  },    
    { name: "Inkatom", pr: "inkatom2nd", dt: "", dr: "", qw: "", reportExcept: "0", periodExcept: "1", periodFakeNg: "1", failureExcept: "1" },
];  

export const withoutExceptTargets = targets.filter(target => target.reportExcept !== "1"); //report에 대한 필요 없는 설비는 제외
export const withoutPeriodExceptTargets = targets.filter(target => target.periodExcept !== "1");  //Search Period에서 자동으로 가져오는거 제외한 것들(양식이 달라서)
export const withoutFailureExceptTargets = targets.filter(target => target.failureExcept !== "1"); //고장율에서 제외한것들


export const tableNames = [      
    "tr2smtpr", "tr2smtdt", "tr2smtqw",
    "tr2crimpingpr", "tr2crimpingdt", "tr2crimpingqw",
    "tr2fpcapr", "tr2fpcadt", "tr2fpcaqw",
    "tr2bfa3ppr", "tr2bfa3pdt", "tr2bfa3pqw", 
    "tr3smtpr", "tr3smtdt", "tr3smtqw",
    "tr3crimpingpr", "tr3crimpingdt", "tr3crimpingqw",    
    "tr3fpcapr", "tr3fpcadt", "tr3fpcaqw",
    "tr3bfa2ppr", "tr3bfa2pdt", "tr3bfa2pqw",
    "tr3bfa3ppr", "tr3bfa3pdt", "tr3bfa3pqw",   
    "tr2bfa2ppr", "tr2bfa2pdt", "tr2bfa2pqw",                         
    "evopr", "evodt", "evoqw",     
    "ev2020_60frpr", "ev2020_60frdt", "ev2020_60frqw",     
    "ev2020_60rrpr", "ev2020_60rrdt", "ev2020_60rrqw",     
    "ev2020_4060frpr", "ev2020_4060frdt", "ev2020_4060frqw",     
    "ev2020_4060rrpr", "ev2020_4060rrdt", "ev2020_4060rrqw",     
    "ford1frpr", "ford1frdt", "ford1frqw",     
    "ford1rrpr", "ford1rrdt", "ford1rrqw",     
    "ford2frpr", "ford2frdt", "ford2frqw",     
    "ford2rrpr", "ford2rrdt", "ford2rrqw",     
    "ford3frpr", "ford3frdt", "ford3frqw",     
    "ford3rrpr", "ford3rrdt", "ford3rrqw",     
    "fca1pr", "fca1dt", "fca1qw",    
    "fca2pr", "fca2dt", "fca2qw",    
    "bt6pr", "bt6dt", "bt6qw",    
    "bmw12vpr", "bmw12vdt", "bmw12vqw",    
    "bmw48vpr", "bmw48vdt", "bmw48vqw",    
    "fca_flex_bcpr", "fca_flex_bcdt", "fca_flex_bcqw",
    "fca_flex_popr", "fca_flex_podt", "fca_flex_poqw",
    "fca_flex_67pr", "fca_flex_67dt", "fca_flex_67qw",
    "fca_flex_nepr", "fca_flex_nedt", "fca_flex_neqw",
    "fca_flex_45pr", "fca_flex_45dt", "fca_flex_45qw",     
    "thermistortapepr", "thermistortapedt",  
    "thermistorpadpr", "thermistorpaddt",
    "bendingevopr", "bendingevodt",
    "inkatom2nd"
];

//Report, DailyReport에서 EOP 된것들을 제외하기 위한 구성
export const withoutEOPLines = [
    'TR2 SMT', 'TR2 Crimping', 'TR2 FPCA', 'TR2 BFA 3P',
    'TR3 SMT', 'TR3 Crimping', 'TR3 FPCA', 'EVO Hybrid', 'EVO 1', 'EVO 2', 'EVO 3',
    'EV2020 60FR', 'EV2020 60RR', 'EV2020 4060FR', 'EV2020 4060RR', 'EV2020 60kW', 'EV2020 4060kW',
    'Ford 2 Front', 'Ford 2 Rear', 'Ford 3 Front', 'Ford 3 Rear',
    'BMW12V', 'BMW48V', 'Inkatom'
];

//searchPeriod에 필요한 것들 tr2smt 이런이름은 위에 targets의 name에서 소문자 띄워쓰기 없음과 동일해야 한다.
export const lineData = {
    tr2smt: {
        shift: [1, 2],
        models: ["2P12S", "3P8S", "EVO_2P", "EVO_3P", "MC25_3P"],        
        ng_types: ["ng_fuse", "ng_thermistor", "ng_fiducial", "ng_qrcode", "ng_burned", "ng_blackfuse", "ng_others"],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', 'Fuse', 'Thermistor', 'Fiducial', 'QR Code', 'Burned', 'Black Fuse', 'Others', 'NG rate', 'Work time', 'Down Time']    
    },
    tr2crimping: {
        shift: [1, 2],
        models: ["2P12S", "3P8S", "EVO_2P", "EVO_3P", "MC25_3P"],
        ng_types: ["ng_caoiheight", "ng_caoishift", "ng_assembly", "ng_machineerror", "ng_weldplate", "ng_fpcb", "ng_etc"],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "C-AOI Height", "C-AOI Shift", 'Assembly', 'Machine Error', 'Weld plate', 'FPCB', 'ETC', 'NG Rate', 'Work time', 'Down Time']
    },
    tr2fpca: {
        shift: [1, 2],
        models: ["2P12S", "3P8S", "EVO_2P", "EVO_3P", "MC25_3P"],
        ng_types: ["ng_fpcbdamage", "ng_toomuchacrylic", "ng_toolessacrylic", "ng_bubbleinacrylic", "ng_acrylicpeeloff", "ng_holesinsolder", "ng_toomuchsolder", "ng_toolesssolder", "ng_qrnotreadable", "ng_eolreal", "ng_contamination", "ng_others",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "FPCB Damage", "Too Much Acrylic", 'Too Less Acrylic', 'Bubble in Acrylic', 'Acryl Peel off', 'Holes in Solder', 'Too Much Solder', 'Too Less Solder', 'QR not Readable', 'EOL', 'Contamination', 'Others', 'NG Rate', 'Work time', 'Down Time']
    },
    tr2bfa3p: {
        shift: [1, 2],
        models: ["3P8S", "3P_GEN.1", "EVO_3P"],
        ng_types: ["ng_heatstacking", "ng_busbardamage", "ng_fpcbdamage", "ng_weldlineposition", "ng_weldingng", "ng_finalslot", "ng_framedamage", "ng_eolreal", "ng_coating", "ng_coverdamage", "ng_weldingpeeltest", "ng_etc",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Heat Stacking", "Busbar Damage", "FPCB Damage", "Weld Line Position", "Welding NG", 'Final Slot', 'Frame Damage', 'EOL Real', 'Coating', 'Cover Damage', 'Welding Peel Test', 'ETC', 'NG Rate', 'Work time', 'Down Time']
    },
    tr3smt: {
        shift: [1, 2],
        models: ["2P12S", "3P8S", "EVO_2P", "EVO_3P", "MC25_3P"],
        ng_types: ["ng_fuse", "ng_thermistor", "ng_fiducial", "ng_qrcode", "ng_burned", "ng_blackfuse", "ng_others"],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', 'Fuse', 'Thermistor', 'Fiducial', 'QR Code', 'Burned', 'Black Fuse', 'Others', 'NG rate', 'Work time', 'Down Time'],
        pr_headers: ['Shift', 'Time', 'Model', 'Target', 'Input', 'OK', 'NG', "Fuse", "Thermistor", "Fiducial", 'QR-Code', 'Burned', 'Black Fuse', 'Others', 'NG Rate', 'Fake Sum', 'S-AOI Fuse',
                     'S-AOI Thermistor', 'Final Inspection', 'Fake NG Rate', 'FPCB Block', 'WEEK CODE', 'WEEK TIN PLATING', 'Work Order', 'Supervisor', 
                     'Leader', 'Technician', 'Shift Name', 'Operator Quantity', 'Work Time', 'row_no'
        ],
        pr_sum_temp: { target: 0, input: 0, ok: 0, ng: 0,
                       ng_fuse: 0, ng_thermistor: 0, ng_fiducial: 0, ng_qrcode: 0, ng_burned: 0, ng_blackfuse: 0, ng_others: 0,
                       fn_saoi_fuse: 0, fn_saoi_thermistor: 0, fn_finalinspection: 0,
        },            
        inputAndNgItem: ["ok", "ng", "fn_saoi_fuse", "fn_saoi_thermistor", "fn_finalinspection"]  //input, ng rate, fake ng sum, fake ng rate를 구하기 위해서 필요, 그래서 ok, ng를 넣고 모든 fakeng 내역을 넣어야 함        
    },
    tr3crimping: {
        shift: [1, 2],
        models: ["2P12S", "3P8S", "EVO_2P", "EVO_3P", "MC25_3P"],
        ng_types: ["ng_caoiheight", "ng_caoishift", "ng_assembly", "ng_machineerror", "ng_weldplate", "ng_fpcb", "ng_etc"],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "C-AOI Height", "C-AOI Shift", 'Assembly', 'Machine Error', 'Weld plate', 'FPCB', 'ETC', 'NG Rate', 'Work time', 'Down Time']
    },
    tr3fpca: {
        shift: [1, 2],
        models: ["2P12S", "3P8S", "EVO_2P", "EVO_3P", "MC25_3P"],
        ng_types: ["ng_fpcbdamage", "ng_toomuchacrylic", "ng_toolessacrylic", "ng_bubbleinacrylic", "ng_acrylicpeeloff", "ng_holesinsolder", "ng_toomuchsolder", "ng_toolesssolder", "ng_qrnotreadable", "ng_eolreal", "ng_contamination", "ng_others",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "FPCB Damage", "Too Much Acrylic", 'Too Less Acrylic', 'Bubble in Acrylic', 'Acryl Peel off', 'Holes in Solder', 'Too Much Solder', 'Too Less Solder', 'QR not Readable', 'EOL', 'Contamination', 'Others', 'NG Rate', 'Work time', 'Down Time']
    },
    evo1: { //TR3 BFA 2P
        shift: [1, 2],
        models: ["2P12S", "EVO_3P", "MC25_3P"],
        ng_types: ["ng_heatstacking", "ng_busbardamage", "ng_fpcbdamage", "ng_weldlineposition", "ng_weldingng", "ng_finalslot", "ng_framedamage", "ng_eolreal", "ng_eol2real", "ng_coating", "ng_coverhook", "ng_coverdamage", "ng_connector", "ng_weldingpeeltest", "ng_etc",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Heat Stacking", "Busbar Damage", "FPCB Damage", "Weld Line Position", "Welding NG", 'Final Slot', 'Frame Damage', 'EOL 1 Real', 'EOL 2 Real', 'Coating', 'Cover Hook', 'Cover Damage', 'Connector', 'Welding Peel Test', 'ETC', 'NG Rate', 'Work time', 'Down Time']
    },
    evo2: { //TR3 BFA 3P
        shift: [1, 2],
        models: ["3P8S", "EVO_3P", "MC25_3P"],
        ng_types: ["ng_heatstacking", "ng_busbardamage", "ng_fpcbdamage", "ng_weldlineposition", "ng_weldingng", "ng_finalslot", "ng_framedamage", "ng_eolreal", "ng_eol2real", "ng_coating", "ng_coverhook", "ng_coverdamage", "ng_connector", "ng_weldingpeeltest", "ng_etc",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Heat Stacking", "Busbar Damage", "FPCB Damage", "Weld Line Position", "Welding NG", 'Final Slot', 'Frame Damage', 'EOL 1 Real', 'EOL 2 Real', 'Coating', 'Cover Hook', 'Cover Damage', 'Connector', 'Welding Peel Test', 'ETC', 'NG Rate', 'Work time', 'Down Time']
    },
    evo3: { //TR2 BFA 2P
        shift: [1, 2],
        models: ["2P12S", "2P_GEN.1", "EVO_3P", "MC25_3P"],
        ng_types: ["ng_heatstacking", "ng_busbardamage", "ng_fpcbdamage", "ng_weldlineposition", "ng_weldingng", "ng_finalslot", "ng_framedamage", "ng_eolreal", "ng_eol2real", "ng_coating", "ng_coverhook", "ng_coverdamage", "ng_connector", "ng_weldingpeeltest", "ng_etc",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Heat Stacking", "Busbar Damage", "FPCB Damage", "Weld Line Position", "Welding NG", 'Final Slot', 'Frame Damage', 'EOL 1 Real', 'EOL 2 Real', 'Coating', 'Cover Hook', 'Cover Damage', 'Connector', 'Welding Peel Test', 'ETC', 'NG Rate', 'Work time', 'Down Time']
    },
    evohybrid: {
        shift: [1, 2],
        models: ["EVO_2P", "EVO_3P", "MC25_3P"],
        ng_types: ["ng_heatstacking", "ng_busbardamage", "ng_fpcbdamage", "ng_weldlineposition", "ng_weldingng", "ng_finalslot", "ng_framedamage", "ng_eolreal", "ng_eol2real", "ng_coating", "ng_coverhook", "ng_coverdamage", "ng_connector", "ng_weldingpeeltest", "ng_etc",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Heat Stacking", "Busbar Damage", "FPCB Damage", "Weld Line Position", "Welding NG", 'Final Slot', 'Frame Damage', 'EOL 1 Real', 'EOL 2 Real', 'Coating', 'Cover Hook', 'Cover Damage', 'Connector', 'Welding Peel Test', 'ETC', 'NG Rate', 'Work time', 'Down Time']
    },
    ev202060fr: {
        shift: [1, 2],
        models: ["60kW Front"],
        ng_types: ["ng_busbarassembly", "ng_heatstacking", "ng_eol", "ng_laserwelding", "ng_slot", "ng_holepositionjig", "ng_finalinspection", "ng_others", "ng_weldingpeeltest", "ng_test",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Busbar Assembly", "Heat Stacking", "EOL", "Laser Welding", "Slot", "Hole Position JIG", 'Final Inspection', 'Others', 'Welding Peel Test', 'Test', 'NG Rate', 'Work time', 'Down Time']
    },
    ev202060rr: {
        shift: [1, 2],
        models: ["60kW Rear"],
        ng_types: ["ng_busbarassembly", "ng_heatstacking", "ng_eol", "ng_laserwelding", "ng_slot", "ng_holepositionjig", "ng_finalinspection", "ng_others", "ng_weldingpeeltest", "ng_test",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Busbar Assembly", "Heat Stacking", "EOL", "Laser Welding", "Slot", "Hole Position JIG", 'Final Inspection', 'Others', 'Welding Peel Test', 'Test', 'NG Rate', 'Work time', 'Down Time']
    },
    ev20204060fr: {
        shift: [1, 2],
        models: ["40kW Front", "60kW Front"],
        ng_types: ["ng_busbarassembly", "ng_heatstacking", "ng_eol", "ng_laserwelding", "ng_slot", "ng_holepositionjig", "ng_finalinspection", "ng_others", "ng_weldingpeeltest", "ng_test",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Busbar Assembly", "Heat Stacking", "EOL", "Laser Welding", "Slot", "Hole Position JIG", 'Final Inspection', 'Others', 'Welding Peel Test', 'Test', 'NG Rate', 'Work time', 'Down Time']
    },
    ev20204060rr: {
        shift: [1, 2],
        models: ["40kW Rear", "60kW Rear"],
        ng_types: ["ng_busbarassembly", "ng_heatstacking", "ng_eol", "ng_laserwelding", "ng_slot", "ng_holepositionjig", "ng_finalinspection", "ng_others", "ng_weldingpeeltest", "ng_test",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Busbar Assembly", "Heat Stacking", "EOL", "Laser Welding", "Slot", "Hole Position JIG", 'Final Inspection', 'Others', 'Welding Peel Test', 'Test', 'NG Rate', 'Work time', 'Down Time']
    },
    ford1front: {
        shift: [1, 2],
        models: ["4P8S", "4P7S", "3P10S", "3P8S", ],
        ng_types: [ "ng_lasermarkingsum", "ng_middleinspsum", "ng_aoingsum", "ng_coatingngsum", "ng_eolngsum", "ng_lqcngsum", "ng_finalinspngsum", ],
        fn_types: [ "fn_lasermarkingsum", "fn_middleinspsum", "fn_aoingsum", "fn_coatingsum", "fn_eolsum", "fn_lqcsum", "fn_finalinspsum",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Laser Marking", "Middle Station", "AOI", "Coating", "EOL", 'LQC', 'Final', 'NG Rate', 'Fake Sum', 'Fake Laser Marking', 'Fake Middle Insp', 'Fake AOI', 'Fake Coating', 'Fake EOL', 'Fake LQC', 'Fake Final', 'Fake NG Rate', 'Work time', 'Down Time']
    },
    ford1rear: {
        shift: [1, 2],
        models: ["4P8S", "4P7S", "3P10S", "3P8S", ],
        ng_types: [ "ng_lasermarkingsum", "ng_middleinspsum", "ng_aoingsum", "ng_coatingngsum", "ng_eolngsum", "ng_lqcngsum", "ng_finalinspngsum", ],
        fn_types: [ "fn_lasermarkingsum", "fn_middleinspsum", "fn_aoingsum", "fn_coatingsum", "fn_eolsum", "fn_lqcsum", "fn_finalinspsum",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Laser Marking", "Middle Station", "AOI", "Coating", "EOL", 'LQC', 'Final', 'NG Rate', 'Fake Sum', 'Fake Laser Marking', 'Fake Middle Insp', 'Fake AOI', 'Fake Coating', 'Fake EOL', 'Fake LQC', 'Fake Final', 'Fake NG Rate', 'Work time', 'Down Time']
    },
    ford2front: {
        shift: [1, 2],
        models: ["4P8S", "4P7S", "3P10S", "3P8S", ],
        ng_types: [ "ng_lasermarkingsum", "ng_middleinspsum", "ng_aoingsum", "ng_coatingngsum", "ng_eolngsum", "ng_lqcngsum", "ng_finalinspngsum", ],
        fn_types: [ "fn_lasermarkingsum", "fn_middleinspsum", "fn_aoingsum", "fn_coatingsum", "fn_eolsum", "fn_lqcsum", "fn_finalinspsum",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Laser Marking", "Middle Station", "AOI", "Coating", "EOL", 'LQC', 'Final', 'NG Rate', 'Fake Sum', 'Fake Laser Marking', 'Fake Middle Insp', 'Fake AOI', 'Fake Coating', 'Fake EOL', 'Fake LQC', 'Fake Final', 'Fake NG Rate', 'Work time', 'Down Time']
    },
    ford2rear: {
        shift: [1, 2],
        models: ["4P8S", "4P7S", "3P10S", "3P8S", ],
        ng_types: [ "ng_lasermarkingsum", "ng_middleinspsum", "ng_aoingsum", "ng_coatingngsum", "ng_eolngsum", "ng_lqcngsum", "ng_finalinspngsum", ],
        fn_types: [ "fn_lasermarkingsum", "fn_middleinspsum", "fn_aoingsum", "fn_coatingsum", "fn_eolsum", "fn_lqcsum", "fn_finalinspsum",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Laser Marking", "Middle Station", "AOI", "Coating", "EOL", 'LQC', 'Final', 'NG Rate', 'Fake Sum', 'Fake Laser Marking', 'Fake Middle Insp', 'Fake AOI', 'Fake Coating', 'Fake EOL', 'Fake LQC', 'Fake Final', 'Fake NG Rate', 'Work time', 'Down Time']
    },
    ford3front: {
        shift: [1, 2],
        models: ["4P8S", "4P7S", "3P10S", "3P8S", ],
        ng_types: [ "ng_lasermarkingsum", "ng_middleinspsum", "ng_aoingsum", "ng_coatingngsum", "ng_eolngsum", "ng_lqcngsum", "ng_finalinspngsum", ],
        fn_types: [ "fn_lasermarkingsum", "fn_middleinspsum", "fn_aoingsum", "fn_coatingsum", "fn_eolsum", "fn_lqcsum", "fn_finalinspsum",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Laser Marking", "Middle Station", "AOI", "Coating", "EOL", 'LQC', 'Final', 'NG Rate', 'Fake Sum', 'Fake Laser Marking', 'Fake Middle Insp', 'Fake AOI', 'Fake Coating', 'Fake EOL', 'Fake LQC', 'Fake Final', 'Fake NG Rate', 'Work time', 'Down Time']
    },
    ford3rear: {
        shift: [1, 2],
        models: ["4P8S", "4P7S", "3P10S", "3P8S", ],
        ng_types: [ "ng_lasermarkingsum", "ng_middleinspsum", "ng_aoingsum", "ng_coatingngsum", "ng_eolngsum", "ng_lqcngsum", "ng_finalinspngsum", ],
        fn_types: [ "fn_lasermarkingsum", "fn_middleinspsum", "fn_aoingsum", "fn_coatingsum", "fn_eolsum", "fn_lqcsum", "fn_finalinspsum",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Laser Marking", "Middle Station", "AOI", "Coating", "EOL", 'LQC', 'Final', 'NG Rate', 'Fake Sum', 'Fake Laser Marking', 'Fake Middle Insp', 'Fake AOI', 'Fake Coating', 'Fake EOL', 'Fake LQC', 'Fake Final', 'Fake NG Rate', 'Work time', 'Down Time']
    },
    fca1: {
        shift: [1, 2],
        models: ["Front", "Rear", ],
        ng_types: ["ng_assembly", "ng_soldering", "ng_aoi", "ng_coating", "ng_eol", "ng_final", ],
        fn_types: ["fn_assembly", "fn_soldering", "fn_aoi", "fn_coating", "fn_eol", "fn_final",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Assembly", "Soldering", "AOI", "Coating", "EOL", 'Final', 'NG Rate', 'Fake Sum', 'Fake Assembly', 'Fake Soldering', 'Fake AOI', 'Fake Coating', 'Fake EOL', 'Fake Final', 'Fake NG Rate', 'Work time', 'Down Time']
    },
    fca2: {
        shift: [1, 2],
        models: ["Front", "Rear", ],
        ng_types: ["ng_assembly", "ng_soldering", "ng_aoi", "ng_coating", "ng_eol", "ng_final", ],
        fn_types: ["fn_assembly", "fn_soldering", "fn_aoi", "fn_coating", "fn_eol", "fn_final",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Assembly", "Soldering", "AOI", "Coating", "EOL", 'Final', 'NG Rate', 'Fake Sum', 'Fake Assembly', 'Fake Soldering', 'Fake AOI', 'Fake Coating', 'Fake EOL', 'Fake Final', 'Fake NG Rate', 'Work time', 'Down Time']
    },
    bt6: {
        shift: [1, 2],
        models: ["Front", "Rear", ],
        ng_types: ["ng_assembly", "ng_soldering", "ng_flatness", "ng_eol", "ng_vision", "ng_final",],
        fn_types: [ "fn_assembly", "fn_soldering", "fn_flatness", "fn_eol", "fn_vision", "fn_aoi",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Assembly", "Soldering", "Flatness", "EOL", "Vision", 'Final', 'NG Rate', 'Fake Sum', 'Fake Assembly', 'Fake Soldering', 'Fake Flatness', 'Fake EOL', 'Fake Vision', 'Fake AOI', 'Fake NG Rate', 'Work time', 'Down Time']
    },
    bmw12v: {
        shift: [1, 2],
        models: ["F40 12V", "HAF", ],
        ng_types: ["ng_lackofcomponents", "ng_coatingtoomuch", "ng_coatingtooless", "ng_coatingbubbles", "ng_solderingtoomuch", "ng_solderingtooless", "ng_solderingballs", "ng_lackofbarcode", "ng_damagebarcode", "ng_busbarscraches", "ng_dirtybusbar", "ng_damagepcb", "ng_dirtyhousing", "ng_damagehousing", "ng_eol", "ng_oqc", ],
        fn_types: [ "fn_soldering", "fn_coating", "fn_eol", "fn_vision",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', "Lack of Components", "Coating too much", "Coating too less", 'Coating bubbles', 'Soldering too much', 'Soldering too less', 'Soldering balls', 'Lack of barcode', 'Damage barcode', 'Busbar scraches', 'Dirty busbar', 'Damage PCB', 'Dirty housing', 'Damage housing', 'EOL', 'OQC', 'NG Rate', 'Fake Sum', 'Fake Soldering', 'Fake Coating', 'Fake EOL', 'Fake Vision', 'Fake NG Rate', 'Work time', 'Down Time']
    },  
    bmw48v: {
        shift: [1, 2],
        models: ["ICB", ],
        ng_types: ["ng_busbarassembly", "ng_pcs", "ng_leaktest", "ng_harnessassembly", "ng_eol", "ng_final", ],        
        search_headers: ['Model', 'Target', 'Input', 'OK Busbar assembly', 'OK PCS', "OK Leak Test", 'OK Harness assembly', 'OK EOL', 'OK Final', 'NG SUM', 'NG Busbar assembly', "NG PCS", 'NG Leak Test', 'NG Harness assembly', 'NG EOL', 'NG Final', 'NG Rate', 'Work time', 'Down Time']
    }, 
    fcaflexbc: {
        shift: [1, 2],
        models: [],
        ng_types: ["ng_brokenbracket", "ng_damagedbusbar", "ng_visiblecopper", "ng_damagedscrew", ],
        fn_types: ["fn_brokenbracket", "fn_damagedbusbar", "fn_visiblecopper", "fn_damagedscrew",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', 'Broken Bracket', 'Damaged Busbar', 'Visible Copper', "Damaged Screw", "NG Rate", 'Fake Sum', 'Fake Broken Bracket', 'Fake Damaged Busbar', 'Fake Visible Copper', 'Fake Damaged Screw', 'Fake NG Rate', 'Work time', 'Down Time']
    },  
    fcaflexpo: {
        shift: [1, 2],
        models: [],
        ng_types: ["ng_damagedcap", "ng_damagedflex", "ng_damagedtape", "ng_visiblecopper", ],
        fn_types: ["fn_wa_klip", "fn_wa_orangetape", "fn_wa_blacktape", "fn_wa_siliconcap", "fn_wa_capplus", "fn_wa_bandcable", "fn_lack_klip", "fn_lack_orangetape", "fn_lack_blacktape", "fn_lack_siliconcap", "fn_lack_capplus", "fn_lack_bandcable",],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', 'Damaged Cap', 'Damaged Flex', 'Damaged Tape', "Visible Copper", "NG Rate", 'Fake Sum', 'Assembly KLIP', 'Assembly Orange Tape', 'Assembly Black Tape', 'Assembly Silicon Cap', 'Assembly CAP +', 'Assembly Band Cable', 'Lack KLIP', 'Lack Orange Tape', 'Lack Black Tape', 'Lack Silicon Cap', 'Lack CAP +', 'Lack Band Cable', 'Fake NG Rate', 'Work time', 'Down Time']
    },
    fcaflex67: {
        shift: [1, 2],
        models: [],
        ng_types: ["ng_damagedcap", "ng_damagedflex", "ng_damagedtape", "ng_visiblecopper", ],
        fn_types: ["fn_wa_klip", "fn_wa_orangetape", "fn_wa_blacktape", "fn_wa_capplus", "fn_wa_capminus", "fn_lack_klip", "fn_lack_orangetape", "fn_lack_blacktape", "fn_lack_capplus", "fn_lack_capminus", ],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', 'Damaged Cap', 'Damaged Flex', 'Damaged Tape', "Visible Copper", "NG Rate", 'Fake Sum', 'Assembly KLIP', 'Assembly Orange Tape', 'Assembly Black Tape', 'Assembly CAP +', 'Assembly CAP -', 'Lack KLIP', 'Lack Orange Tape', 'Lack Black Tape', 'Lack CAP +', 'Lack CAP -', 'Fake NG Rate', 'Work time', 'Down Time']
    },
    fcaflexne: {
        shift: [1, 2],
        models: [],
        ng_types: ["ng_damagedcap", "ng_damagedflex", "ng_damagedtape", "ng_visiblecopper", ],
        fn_types: ["fn_wa_klip", "fn_wa_orangetape", "fn_wa_blacktape", "fn_wa_siliconcap", "fn_wa_capminus", "fn_lack_klip", "fn_lack_orangetape", "fn_lack_blacktape", "fn_lack_siliconcap", "fn_lack_capminus", ],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', 'Damaged Cap', 'Damaged Flex', 'Damaged Tape', "Visible Copper", "NG Rate", 'Fake Sum', 'Assembly KLIP', 'Assembly Orange Tape', 'Assembly Black Tape', 'Assembly Silicon Cap', 'Assembly CAP -', 'Lack KLIP', 'Lack Orange Tape', 'Lack Black Tape', 'Lack Silicon Cap', 'Lack CAP -', 'Fake NG Rate', 'Work time', 'Down Time']
    }, 
    fcaflex45: {
        shift: [1, 2],
        models: [],
        ng_types: ["ng_damagedcap", "ng_damagedflex", "ng_damagedtape", "ng_visiblecopper", ],
        fn_types: ["fn_wa_klip", "fn_wa_orangetape", "fn_wa_blacktape", "fn_wa_capplus", "fn_wa_capminus", "fn_lack_klip", "fn_lack_orangetape", "fn_lack_blacktape", "fn_lack_capplus", "fn_lack_capminus", ],
        search_headers: ['Model', 'Target', 'Input', 'OK', 'NG', 'Damaged Cap', 'Damaged Flex', 'Damaged Tape', "Visible Copper", "NG Rate", 'Fake Sum', 'Assembly KLIP', 'Assembly Orange Tape', 'Assembly Black Tape', 'Assembly CAP +', 'Assembly CAP -', 'Lack KLIP', 'Lack Orange Tape', 'Lack Black Tape', 'Lack CAP +', 'Lack CAP -', 'Fake NG Rate', 'Work time', 'Down Time']
    }, 
    thermistortape: {
        shift: [1, 2],
        models: ["EVO_2P", "EVO_3P", "MC25_3P"],
        ng_types: ["ng_coverdamage",],
        search_headers: ['Model', 'Target', 'Input', 'OK Total', 'OK A', 'OK B', 'OK C', 'NG', 'Cover Damage', "NG Rate", 'Work time', 'Down Time']
    }, 
    thermistorpad: {
        shift: [1, 2],
        models: ["EVO_2P", "EVO_3P", "MC25_3P"],
        ng_types: ["ng_fpcadamage", "ng_thermistordamage"],
        search_headers: ['Model', 'Target', 'Input', 'OK Total', 'OK A', 'OK B', 'OK C', 'NG', 'FPCA Damage', 'Thermistor Damage', "NG Rate", 'Work time', 'Down Time']
    }, 
    bendingevo: {
        shift: [1, 2],
        models: [],
        ng_types: [
              "ng_3p", "ng_3p_fpcadamage", "ng_3p_coating", "ng_3p_connector",
              "ng_3p_2", "ng_3p_fpcadamage_2", "ng_3p_coating_2", "ng_3p_connector_2",
              "ng_3p_3", "ng_3p_fpcadamage_3", "ng_3p_coating_3", "ng_3p_connector_3",
              "ng_2p", "ng_2p_fpcadamage", "ng_2p_coating", "ng_2p_connector"
        ],    
        search_headers: [ 'Model', 'Target', 'Input', 'OK Total','OK 3P H', 'OK 3P 2', 'OK 3P 3', 'OK 2P H', 'NG Total',
                'NG 3P H', "3P FPCA Damage H", "3P Coating H", '3P Acrylic inside connector H',
                'NG 3P 2', "3P FPCA Damage 2", "3P Coating 2", '3P Acrylic inside connector 2',
                'NG 3P 3', "3P FPCA Damage 3", "3P Coating 3", '3P Acrylic inside connector 3',
                'NG 2P H', "2P FPCA Damage H", "2P Coating H", '2P Acrylic inside connector H',                         
                'NG Rate', 'Work Time', 'Down Time'
        ]
    }, 
};
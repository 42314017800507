<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <h3>Add Production Plan for {{ selectedLine2 }}</h3> 
          <button class="close-button" @click="closeModal">X</button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <!--Modal 창에 나오는 입력 Data들 Database table 변경시에 추가/삭제 해야 됨 -시작- -->
            <table>
            <tr>            
              <td>
              <div class="form-group date-time-group">
                <label>Year/Month/Day:</label>
                <div class="date-time-inputs">
                  <input type="text" id="year" v-model="searchDate.year"  />
                  <input type="text" id="month" v-model="searchDate.month" />
                  <input type="text" id="day" v-model="searchDate.day" />                  
                </div>
              </div>
              <div class="form-group">
                <label for="factory">Factory:</label>                          
                <select id="factory" v-model="formInputs.factory">            
                  <option>1</option><option>2</option>
                </select>  
              </div>
            <div class="form-group">
              <label for="line">Line:</label>
              <select id="line" v-model="formInputs.line" @change="updateModel()">
                <option>BMW 48V</option><option>BMW 12V</option><option>BT6</option><option>FCA 1</option>
                <option>FCA 2</option><option>FCA FLEX BC</option><option>FCA FLEX PO</option><option>FCA FLEX 67</option>
                <option>FCA FLEX 45</option><option>FCA FLEX NE</option><option>Ford 1 Front</option><option>Ford 1 Rear</option>
                <option>Ford 2 Front</option><option>Ford 2 Rear</option><option>Ford 3 Front</option><option>Ford 3 Rear</option>
                <option>TR2 SMT</option><option>1st Semi Check</option><option>TR2 Crimping</option><option>TR2 FPCA</option><option>TR2 BFA 2P</option><option>TR2 BFA 3P</option>
                <option>TR3 SMT</option><option>2nd Semi Check</option><option>TR3 Crimping</option><option>TR3 FPCA</option>
                <option>EVO 1</option><option>EVO 2</option><option>EVO 3</option><option>EVO Hybrid</option>
                <option>Bending 1</option><option>Bending 2</option>
                <option>EV2020 4060kW Front</option><option>EV2020 4060kW Rear</option><option>EV2020 60kW Front</option><option>EV2020 60kW Rear</option>
                <option>EV2020 Packing</option><option>EV2020 FFC Packing</option>
              </select>
            </div>
            <div class="form-group">
              <label for="shift">Shift:</label>                          
              <select id="shift" v-model="formInputs.shift">            
                <option>Day</option><option>Night</option>
              </select>  
            </div>
            </td>
            <td>
            <div class="form-group">
              <label for="model">Model:</label>                          
              <select id="model" v-model="formInputs.model">
                <option v-for="option in modelOptions" :key="option">{{ option }}</option>
              </select>
            </div>      
            <div class="form-group">
              <label for="code">Product Code:</label>                          
              <input type="text" id="code" v-model="formInputs.code" readonly class="readWindow"/>
            </div>       
            <div class="form-group">
              <label for="planquantity">Plan Quantity:</label>
              <input type="number" id="planquantity" v-model="formInputs.planquantity" />
            </div>
            <div class="form-group">
              <label for="workorder">Work Order:</label>
              <input type="number" id="workorder" v-model="formInputs.workorder" />
            </div>
            </td>
            <td>
              <div class="form-group">
                <label for="leader">Leader:</label>
                <input type="text" id="leader" v-model="formInputs.leader" />
              </div>
              <div class="form-group">
                <label for="technician">Technician:</label>
                <input type="text" id="technician" v-model="formInputs.technician" />
              </div>
              <div class="form-group">
                <label for="operator">Operator:</label>
                <input type="text" id="operator" v-model="formInputs.operator" />
              </div>
              <div class="form-group">
                <label for="qualityworker">Quality Worker:</label>
                <input type="text" id="qualityworker" v-model="formInputs.qualityworker" />
              </div>
              </td>
            <td>
            <div class="form-group" style="display: flex; flex-direction: column;">
              <label for="notice">Notice</label>
              <textarea id="notice" v-model="formInputs.notice" rows="4" cols="25"></textarea>              
            </div>                                           
            </td>
            </tr>
            </table>
            <button type="submit" :class="{ 'disabled': isProcessing }" @click.prevent="submitForm" :disabled="isProcessing">Save</button>
            {{ saveStatus }} 
            </form>
         </div>        
      </div>      
    </div>
  </div>
</template>
  
  <script>  
  //데일리 레포트쪽은 dbtablename과 linename 2개만 바꾸면 끝남
  import { reactive, watch, ref } from 'vue';  
  import axios from 'axios';   
  import { getCurrentDate, } from "../../utils.js"; 
   
  export default {                   
    setup(props, { emit }) {   
        const dbTableName = "weekplan"    
        const saveStatus = ref(''); 
        const isProcessing = ref(false);             

        //년.월.일을 제외하고는 다 여기에 입력해줘야 함 -시작-        
        const formInputs = reactive({
          factory: null,
          line: null,
          shift: null,
          model: null,
          code: null,
          planquantity: null,
          workorder: null,
          leader: null,
          technician: null,
          operator: null,
          qualityworker: null,
          notice: null,          
        });
        // -끝-           
      
        const searchDate = reactive({ ...getCurrentDate(), });   

        const modelOptions = ref([]); 

        const modelCode = {
          'BMW 48V' : {'GEN1.5' : '2184104'},
          'BMW 12V' : {'F40' : '2179213', 'HAF': '2174724'},
          'BT6' : {'Front' : '2174220', 'Rear': '2147221'},
          'FCA 1' : {'Front' : '2182418', 'Rear': '2182419'},
          'FCA 2' : {'Front' : '2182418', 'Rear': '2182419'},
          'FCA FLEX PO' : {'Positive' : '2179721', },
          'FCA FLEX NE' : {'Negative' : '2179722', },
          'FCA FLEX BC' : {'Busbar Carrier' : '2182420', },
          'FCA FLEX 45' : {'4-5+' : '2179724', },
          'FCA FLEX 67' : {'6+7-' : '2179723', },
          'EV2020 4060kW Front' : {'40kW' : '2183495', '60kW': '2183497' },
          'EV2020 4060kW Rear' : {'40kW' : '2183496', '60kW': '2183498' },
          'EV2020 60kW Front' : {'60kW': '2183497' },
          'EV2020 60kW Rear' : {'60kW': '2183498' },
          'EV2020 Packing' : {'40kW' : '1', '60kW': '2' },
          'EV2020 FFC Packing' : {'FFC' : '3', },
          'Ford 1 Front' : {'4P8S': '2187237', '4P7S': '2187239', '3P10S': '2187241', '3P8S': '2187243' },
          'Ford 1 Rear' : {'4P8S': '2187238', '4P7S': '2187240', '3P10S': '2187242', '3P8S': '2187244' },
          'Ford 2 Front' : {'4P8S': '2187237', '4P7S': '2187239', '3P10S': '2187241', '3P8S': '2187243' },
          'Ford 2 Rear' : {'4P8S': '2187238', '4P7S': '2187240', '3P10S': '2187242', '3P8S': '2187244' },
          'Ford 3 Front' : {'4P8S': '2187237', '4P7S': '2187239', '3P10S': '2187241', '3P8S': '2187243' },
          'Ford 3 Rear' : {'4P8S': '2187238', '4P7S': '2187240', '3P10S': '2187242', '3P8S': '2187244' },
          'TR2 SMT' : {'2P12S': '6202416', '3P8S': '6202413', 'EVO_2P': '6202439', 'EVO_3P': '6202437' },
          '1st Semi Check' : {'2P12S': '6202416', '3P8S': '6202413', 'EVO_2P': '6202439', 'EVO_3P': '6202437' },
          'TR2 Crimping' : {'2P12S': '6202415', '3P8S': '6202412', 'EVO_2P': '6202438', 'EVO_3P': '6202436' },
          'TR2 FPCA' : {'2P12S': '6202414', '3P8S': '6202411', 'EVO_2P': '6202391', 'EVO_3P': '6202392' },
          'TR2 BFA 2P' : {'2P12S': '2175902', 'EVO_2P': '2189891', '2P_GEN.1': '2187932' },
          'TR2 BFA 3P' : {'3P8S': '2175903', 'EVO_3P': '2189892', '3P_GEN.1': '2187933' },
          'TR3 SMT' : {'2P12S': '6202416', '3P8S': '6202413', 'EVO_2P': '6202439', 'EVO_3P': '6202437' },
          '2nd Semi Check' : {'2P12S': '6202416', '3P8S': '6202413', 'EVO_2P': '6202439', 'EVO_3P': '6202437' },
          'TR3 Crimping' : {'2P12S': '6202415', '3P8S': '6202412', 'EVO_2P': '6202438', 'EVO_3P': '6202436' },
          'TR3 FPCA' : {'2P12S': '6202414', '3P8S': '6202411', 'EVO_2P': '6202391', 'EVO_3P': '6202392' },
          'EVO 1' : {'2P12S': '2175902', 'EVO_3P': '2189892' },
          'EVO 2' : {'3P8S': '2175903', 'EVO_3P': '2189892' },       
          'EVO 3' : {'EVO_3P': '2189892' },   
          'EVO Hybrid' : {'EVO_2P': '2189891', 'EVO_3P': '2189892' },
          'Bending 1' : {'EVO_2P': '6202391', 'EVO_3P': '6202392' },
          'Bending 2' : {'EVO_2P': '6202391', 'EVO_3P': '6202392' },
        }

        const updateModel = () => {          
            if (formInputs.line === 'BMW 48V') { modelOptions.value = ['GEN1.5',];}
            else if (formInputs.line === 'BMW 12V') { modelOptions.value = ['F40', 'HAF',]; }
            else if (formInputs.line === 'BT6') { modelOptions.value = ['Front', 'Rear',]; }
            else if (formInputs.line === 'FCA 1') { modelOptions.value = ['Front', 'Rear',]; }
            else if (formInputs.line === 'FCA 2') { modelOptions.value = ['Front', 'Rear',]; }
            else if (formInputs.line === 'FCA FLEX PO') { modelOptions.value = ['Positive',] ; }
            else if (formInputs.line === 'FCA FLEX NE') { modelOptions.value = ['Negative',] ; }
            else if (formInputs.line === 'FCA FLEX BC') { modelOptions.value = ['Busbar Carrier',] ; }
            else if (formInputs.line === 'FCA FLEX 45') { modelOptions.value = ['4-5+',] ; }
            else if (formInputs.line === 'FCA FLEX 67') { modelOptions.value = ['6+7-',] ; }
            else if (formInputs.line === 'EV2020 4060kW Front') { modelOptions.value = ['40kW','60kW'] ; }
            else if (formInputs.line === 'EV2020 4060kW Rear') { modelOptions.value = ['40kW','60kW'] ; }
            else if (formInputs.line === 'EV2020 60kW Front') { modelOptions.value = ['60kW'] ; }
            else if (formInputs.line === 'EV2020 60kW Rear') { modelOptions.value = ['60kW'] ; }
            else if (formInputs.line === 'EV2020 Packing') { modelOptions.value = ['40kW','60kW'] ; }
            else if (formInputs.line === 'EV2020 FFC Packing') { modelOptions.value = ['FFC'] ; }
            else if (formInputs.line === 'Ford 1 Front') { modelOptions.value = ['4P8S', '4P7S', '3P10S', '3P8S'] ; }
            else if (formInputs.line === 'Ford 1 Rear') { modelOptions.value = ['4P8S', '4P7S', '3P10S', '3P8S'] ; }
            else if (formInputs.line === 'Ford 2 Front') { modelOptions.value = ['4P8S', '4P7S', '3P10S', '3P8S'] ; }
            else if (formInputs.line === 'Ford 2 Rear') { modelOptions.value = ['4P8S', '4P7S', '3P10S', '3P8S'] ; }
            else if (formInputs.line === 'Ford 3 Front') { modelOptions.value = ['4P8S', '4P7S', '3P10S', '3P8S'] ; }
            else if (formInputs.line === 'Ford 3 Rear') { modelOptions.value = ['4P8S', '4P7S', '3P10S', '3P8S'] ; }
            else if (formInputs.line === 'TR2 SMT') { modelOptions.value = ['2P12S', '3P8S', 'EVO_2P', 'EVO_3P'] ; }
            else if (formInputs.line === '1st Semi Check') { modelOptions.value = ['2P12S', '3P8S', 'EVO_2P', 'EVO_3P'] ; }
            else if (formInputs.line === 'TR2 Crimping') { modelOptions.value = ['2P12S', '3P8S', 'EVO_2P', 'EVO_3P'] ; }
            else if (formInputs.line === 'TR2 FPCA') { modelOptions.value = ['2P12S', '3P8S', 'EVO_2P', 'EVO_3P'] ; }
            else if (formInputs.line === 'TR2 BFA 2P') { modelOptions.value = ['2P12S', 'EVO_2P', '2P_GEN.1'] ; }
            else if (formInputs.line === 'TR2 BFA 3P') { modelOptions.value = ['3P8S', 'EVO_3P', '3P_GEN.1'] ; }
            else if (formInputs.line === 'TR3 SMT') { modelOptions.value = ['2P12S', '3P8S', 'EVO_2P', 'EVO_3P'] ; }
            else if (formInputs.line === '2nd Semi Check') { modelOptions.value = ['EVO_2P', 'EVO_3P'] ; }
            else if (formInputs.line === 'TR3 Crimping') { modelOptions.value = ['2P12S', '3P8S', 'EVO_2P', 'EVO_3P'] ; }
            else if (formInputs.line === 'TR3 FPCA') { modelOptions.value = ['2P12S', '3P8S', 'EVO_2P', 'EVO_3P'] ; }
            else if (formInputs.line === 'EVO 1') { modelOptions.value = ['EVO_3P'] ; }
            else if (formInputs.line === 'EVO 2') { modelOptions.value = ['EVO_3P'] ; }
            else if (formInputs.line === 'EVO 3') { modelOptions.value = ['EVO_3P'] ; }
            else if (formInputs.line === 'EVO Hybrid') { modelOptions.value = ['EVO_2P', 'EVO_3P'] ; }
            else if (formInputs.line === 'Bending 1') { modelOptions.value = ['EVO_2P', 'EVO_3P'] ; }
            else if (formInputs.line === 'Bending 2') { modelOptions.value = ['EVO_2P', 'EVO_3P'] ; }

            else { modelOptions.value = ['Please select a line']; }

            if (formInputs.line in modelCode && formInputs.model in modelCode[formInputs.line]) {
                formInputs.code = modelCode[formInputs.line][formInputs.model];
              } else {
                formInputs.code = '';
              }
        };        

        watch(() => [formInputs.line, formInputs.model], updateModel);

        const getColumnMapping = () => {
            const inputs = document.querySelectorAll("input, select, textarea");
            const filteredInputs = Array.from(inputs).filter(input => input.id !== 'file-input');
            const mapping = {};
  
            filteredInputs.forEach(input => {
              let inputId = input.id;
  
              if (inputId === "request_hour" || inputId === "request_minute") {
                inputId = "request_time";
              } 
              if (!mapping[inputId]) {
                if (inputId === "request_time") {
                  mapping[inputId] = "request_time";
                } else {
                  mapping[inputId] = input.id;
                }
              }
            });  
            return mapping;
          };
        //끝 

        //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -시작-
        const submitForm = async () => {
          if (!formInputs.line || !formInputs.model || !formInputs.code || !formInputs.shift) {
            alert("Please fill in all the fields: Shift, Line, Model, and Product Code.");
            return;
          }

          isProcessing.value = true; // 작업 시작 전 isProcessing을 true로 설정
          saveStatus.value = 'Processing...';

          const columnMapping = getColumnMapping();

          const formData = {
            tableName: dbTableName,
            columnMapping: columnMapping,
            values: {
              year: searchDate.year,
              month: searchDate.month,
              day: searchDate.day,
              factory: formInputs.factory,
              line: formInputs.line,
              shift: formInputs.shift,
              model: formInputs.model,
              code: formInputs.code,
              planquantity: formInputs.planquantity,
              workorder: formInputs.workorder,
              leader: formInputs.leader,
              technician: formInputs.technician,
              operator: formInputs.operator,
              qualityworker: formInputs.qualityworker,
              notice: formInputs.notice,
            }
         };         
          
          try {
            await axios.post('/api/post', formData);
            saveStatus.value = 'Completed'; // 성공적으로 데이터 전송 완료
          } catch (error) {
            console.error('Error during submission:', error);
            saveStatus.value = 'Error'; // 오류 발생 시 상태 업데이트
          } finally {
            isProcessing.value = false; // 작업 완료 후 isProcessing을 false로 설정
          }
         
          closeModal();
        };

        const closeModal = () => { emit("onClose"); };
        
      //api-server.js로 data 저장 요청하는거 values: 안에는 table에 있는 이름으로 다 적어줘야 함 -끝-    
  
      return { searchDate, dbTableName, formInputs, submitForm, closeModal, updateModel, modelOptions,
               selectedLine2: props.selectedLine, saveStatus, isProcessing  };

    },     

    props: {
      onSubmit: { type: Function, required: true, },     
      selectedLine: { type: String, default: "", }, 
    }, 

};
  
  </script>
    
    <style scoped>
    /* Reset */
    * { margin: 0; padding: 0; box-sizing: border-box; }
  
    /* Modal styles */
    .modal-mask {
      position: fixed; z-index: 9998;
      top: 0; left: 0;
      width: 100%; height: 100%;
      background-color: rgba(0, 0, 0, 0.5); padding-left: 20px; padding-top: 20px; 
      display: flex; justify-content: flex-start; align-items: flex-start; 
    }   
    
    .modal-wrapper { width: 100%;  max-width: 1000px; }
    
    .modal-container {
      background-color: #ffffff;
      border-radius: 12px; padding: 20px;
      max-height: 80vh; overflow-y: auto; font-size: 0.8rem; 
    }
    
    .modal-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem; }
  
    .modal-header h3 { font-size: 1.2rem; }
  
    .close-button { background-color: transparent; border: none; font-weight: bold; font-size: 1.2rem; cursor: pointer; }
  
    .form-group { display: flex; flex-direction: column; gap: 5px; margin-bottom: 15px; }
  
    label { font-weight: bold; }
  
    input,textarea,select { font-size: 0.7rem;  padding: 8px 12px; border: 1px solid #ccc; border-radius: 4px; }
  
    input[type="number"] { width: 100%;}  
  
    .readWindow[readonly] { background-color: #f0f0f0; }
  
    button[type="submit"] {
      background-color: #4CAF50; color: white;
      padding: 5px 20px;
      border: none; border-radius: 4px; cursor: pointer;
      text-align: center; text-decoration: none; font-size: 16px;
      display: inline-block; transition-duration: 0.4s;
    }
  
    button[type="submit"]:hover { background-color: #45a049; }
  
    table { table-layout: fixed; width: 100%; }
  
    td { width: 20%; vertical-align: top; padding: 0 15px; }
    
    .time-inputs { display: flex; gap: 5px; }
    .time-inputs input[type="number"] { width: 50px; }
    .date-time-inputs { display: flex; gap: 5px;}
    .date-time-inputs input { width: 50px; size: 8px; }
  
    .ng-dropdown-container { position: relative; display: inline-block; }
    .ng-dropdown-button { font-size: 12px; padding: 2px 4px; margin-left: 4px;}
    .ng-dropdown-menu { 
      position: absolute; padding: 4px;
      left: 0; top: 100%; z-index: 10; 
      background-color: white; border: 1px solid #ccc;  
    }
    .ng-dropdown-menu > div { cursor: pointer; padding: 2px 8px; }
    .ng-dropdown-menu > div:hover { background-color: #f5f5f5; }
  
    .ngmaintype{ max-width: 120px; min-width: 120px;}
  </style>
